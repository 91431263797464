@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;
@use '~theme/table' as *;

.detailed-info-event-log-container {
    margin: 10px 0;

    .detailed-info-event-groups {
        padding: 8px 0px 10px 0px;

        .event-log-no-information {
            text-align: center;
            color: $mine-shaft-transparent;
        }
    }

    .expandable-component {
        border-top: solid $white;
        .header {
            background-color: $wild-sand;
            min-height: 40px;
            height: fit-content;
            -webkit-text-fill-color: $black;
        }

        .event-group {
            display:flex;
            align-items: center;
            .event-name {
                padding-right: 6px;
                word-break: break-word;
            }
            .event-type-icon {
                display: flex;
                align-items: center;
                padding-left: 21px;
                padding-right: 17px;
            }
        }
    }

    .rc-table-thead {
        @include font-medium-text-sm;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: white;
        color: $dove-gray;
        line-height: 14.06px;
        padding: 2px;
        text-transform: uppercase;

        .rc-table-cell {
            padding: 8px;
        }
    }

    .ungrouped-events {
        .rc-table-cell:first-of-type {
            .general-table-title {
                margin-left: 37px;
            }
        }
    }

    .grouped-events {
        .rc-table-cell:first-of-type {
            .general-table-title {
                margin-left: 0px;
            }
        }
    }

    table {
        border-collapse: separate;
        border-spacing: 0;
    }

    table th {
        border-bottom: 1px solid $black;
    }

    table td {
        border-bottom: 1px solid $silver;
    }

    .rc-table-cell-fix-left-last {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
    }

    .rc-table-row {
        @include font-regular-text-md;
        border-bottom: 1px solid $silver-transparent;
        line-height: 20px;

        .rc-table-cell {
            padding: 10px;

            .event-name-with-type {
                display: flex;
                .event-type-icon {
                    display: flex;
                    align-items: center;
                    margin-right: 17px;
                }
                div {
                    margin: 0 2px;
                    line-height: 16px;
                }
            }

            .close-button > span {
                cursor: pointer;
            }

            .other-info {
                display: flex;

                .action-icon {
                    padding-right: 10px;
                }
            }
        }
    }

    .rc-table-ping-left {
        .rc-table-cell-fix-left {
            @include fixed-column;
        }
    }
}

.close-event-dialog.modal {
    .modal-header {
        padding-left: 25px;
    }

    .modal-body {
        @include font-regular-text-md;
        color: $dove-gray;

        .event-cause-ddn {
            margin-top: 5px;
            float: none;
        }

        .text-area-container {
            margin: 10px 0;
        }
    }
}


