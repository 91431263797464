@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use "~theme/breakpoints" as *;

.self-evaluate-comments-section {
	@include font-regular-text-md;
    line-height: 16px;

	.comments-actions {
		position: relative;
		@include xl-max-w {
			padding-bottom: 15px;
		}

		.self-evaluate-comment-label {
			@include font-medium-text-lg;
			color: $mine-shaft;
		}

		.select-cause-container {
			padding-top: 15px;

			.select-cause-label {
				font-size: 14px;
				color: $dove-gray;
			}

			.general-select-container {
				padding-top: 5px;
			}
		}

		.preselection-container {
			display: flex;
			align-items: center;
			padding-top: 20px;

			.preselection-label {
				padding-left: 8px;
			}
		}

		.event-comment {
			padding-top: 20px;

			.comment-wrapper {
				display: flex;
				justify-content: space-between;

				.comment-label {
					font-size: 14px;
					color: $dove-gray;
				}
			}

			.text-area-container {
				margin-left: 0;

				.text-area-component {
					padding: 5px;
					height: calc(100vh - 700px);
				}
			}
		}

		.action-button-container {
			text-align: right;
			padding-top: 15px;

			.action-button {
				width: 142px;
			}
		}
	}

	.comment-list {
		position: relative;
		.comment-list-label {
			@include font-medium-text-lg;
			color: $mine-shaft;
		}

		.cause-filter-label {
			padding-top: 15px;
			font-size: 14px;
			color: $dove-gray;
		}

		.general-select-container {
			padding-top: 6px;
			padding-bottom: 20px;
		}

		.comment-wrapper {
			display: flex;
			justify-content: space-between;
			background-color: $wild-sand;
			margin: 4px 0;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			.comment-details {
				padding: 8px 0 8px 8px;
				color: $tundora;
				font-size: 14px;
				line-height: 16px;

				.comment-header {
					font-weight: 600;
					padding-bottom: 7px;
				}

				.user-comment-text {
					word-break: break-word;
					text-align: justify;
					padding-right: 0.25rem;
				}
			}

			.delete-comment-icon-wrapper {
				margin: 22px 0;
			}

			.delete-comment-icon {
				cursor: pointer;
				padding: 20px 18px;
				border-left: 1px solid $alto;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
	}
}
