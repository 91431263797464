@use '~theme/colors'as *;
@use '~theme/mixins'as *;
@use '~theme/typography'as *;


.powertrain-asset-overview-filters-container {
    @include create-border($wild-sand, 4);
    display: flex;
    background-color: $wild-sand;
    margin-top: 20px;

    .filter-section {
        padding: 8px 16px 8px 16px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        @include font-regular-text-md;

        .section-title {
            @include font-bold-text-md;
        }

        &.asset-type-filter {
            min-width: 200px;

            .general-select__placeholder {
                color: $mine-shaft;
            }

            .general-select__menu .general-select__menu-list .general-select__option {
                display: flex;
                align-items: center;
                column-gap: 10px;
            }

            .general-select__value-container {
                svg {
                    margin-bottom: 2px;
                }
            }
        }

        &.reset-filters {
            align-items: end;
            margin-top: 30px;
            width: 100%;
        }
    
        &.condition-index-filter {
            min-width: 535px;

            .condition-indexes {
                align-items: center;
                display: flex;
                flex: 1;

                .item {
                    display: flex;
                    margin-right: 15px;
        
                    .condition-index {
                        @include create-border($silver);
                        cursor: pointer;
                        padding: 1px;
                        height: 24px;
                        width: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 4px;
                        margin-top: -2px;
                        background-color: $white;
        
                        &.selected {
                            @include create-border($dodger-blue);
                            background-color: $pattens-blue;
                        }

                        &.disabled {
                            cursor: default;
                        }
                    }
                }
            }
        }
    }

    .separator {
        width: 1px;
        background-color: $silver;
        height: unset;
        margin: 0.5rem;
    }
}