@use '~theme/colors'as *;
@use '~theme/mixins'as *;
@use '~theme/typography'as *;
@use "~theme/breakpoints" as *;

.event-detailsSS-content {
    margin-top: 25px;

	.event-details-header {
		@include font-regular-text-md;
		display: flex;
        align-items: center;
		background-color: $wild-sand;
		margin: 0;
        line-height: 16px;
		padding: 15px;
		padding-bottom: 0;

        .event-type-icon{
            display:flex;
            align-items: start;
            padding-bottom: 14px;
        }
		.event-type-group {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 100%;
			@include customScreenMinWidth(1281) {
				flex-direction: row;
                justify-content: flex-start;
			}
			.event-log-additional-details {
                display: flex;
                flex-direction: column;
                padding-bottom: 0;
                @include customScreenMinWidth(1281) {
                    flex-direction: row;
                    justify-content: flex-start;
                }
                & > * {
                    padding: 0 9px 14px 0;
                    min-width: 220px;
                    word-break: break-word;
                }
			}
			& > * {
				padding: 0 9px 14px 9px;
				min-height: 1rem;
			}
		}

		.back-to-event-list {
			@include font-medium-text-md;
			min-width: 200px;
			flex: auto;

			.switch-component {
				float: right;
				.event-list-lbl {
					text-decoration: underline;
					color: $dodger-blue;
					cursor: pointer;
				}
			}
		}

	}

    .event-details-comments-section {
        margin-top: 25px;

        .event-details-comments-actions {
            width: 40%;

            .close-or-comment-label {
                @include font-medium-text-lg;
                color: $mine-shaft;
            }

            .selects-container {
                font-family: 'ABBvoice-Regular', sans-serif;
                display: flex;
                margin: 15px 0px;

                .select-action {
                    width: 100%;
                    margin-right: 25px;

                    .action-label {
                        font-size: 14px;
                        color: $dove-gray;
                    }

                    .action-dropdown {
                        width: 100%;
                    }
                }

                .select-cause {
                    width: 100%;

                    .cause-label {
                        font-size: 14px;
                        color: $dove-gray;
                    }

                    .cause-dropdown {
                        width: 100%;
                    }
                }
            }

            .action-comment {
				font-family: 'ABBvoice-Regular', sans-serif;

                .comment-label {
                    font-size: 14px;
                    color: $dove-gray;
                }

                .comment-length {
                    float: right;
                }

                .action-textarea {
                    margin: 0px;

                    .text-area-component {
                        height: calc(100vh - 615px);
                        padding: 5px;
                    }

                    ::placeholder {
                        color: $silver-chalice;
                    }
                }
            }

            .action-button-container {
                float: right;

                .action-button {
                    width: 142px;
                    color: $mine-shaft;
                    margin: 10px 0px;
                }
            }
        }

        .event-details-comments {
            width: 60%;

            .event-comments-label {
                @include font-medium-text-lg;
                color: $mine-shaft;
                margin-bottom: 15px;
            }

            .event-details-comment {
                background-color: $wild-sand;
                padding: 10px;
                margin-bottom: 5px;
                color: $tundora;

                .comment-user-time {
                    font-weight: 600;
                    margin-bottom: 5px;
					font-family: 'ABBvoice-Bold', sans-serif;
                }

				.user-comment {
					font-family: 'ABBvoice-Regular', sans-serif;
                    word-break: break-all;
				}
            }

            .no-comment-available {
                text-align: center;
                color: $mine-shaft-transparent;
            }
        }
    }
}