@use '~theme/colors'as *;
@use '~theme/mixins'as *;
@use '~theme/typography'as *;

.cross-asset-analysis-container .sidepanel-container .asset-list-container {
    @include font-regular-text-md;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-top: 1px solid $mine-shaft;
    width: 250px;
    height: 100%;
    line-height: 16px;
    margin-top: 24px;
    background-color: $wild-sand;
    padding-right: 10px;

    .loading-indicator-content {
        height: 200px;
    }

    .kpi-list {
        display: flex;
        flex-direction: column;

        .kpi-list-empty {
            display: flex;
            align-items: center;
            padding: 8px;
            min-height: 40px;
            overflow-wrap: break-word;
            justify-content: space-around;
            text-align: center;
        }

        .kpi-list-item {
            @include shadow-inset-alto;
            display: flex;
            align-items: center;
            padding: 8px;
            min-height: 40px;
            cursor: pointer;
            overflow-wrap: break-word;

            &:hover {
                background-color: $concrete;
                cursor: pointer;
            }

            .kpi-name {
                width: 80%;
                padding: 0px 12px;
            }

            &.selected {
                background-color: $light-blue;
            }

            .parameter-signal-label {
                @include font-medium-text-md;
            }

            .dropright .dropdown-toggle::after {
                display: none;
            }

            .kpi-name-img-container {
                width: 20%;
                text-align: right;
                padding-right: 5px;
            }
        }
    }

    .asset-list {
        display: flex;
        flex-direction: column;

        .asset-list-empty {
            display: flex;
            align-items: center;
            padding: 8px;
            min-height: 40px;
            overflow-wrap: break-word;
            justify-content: space-around;
        }

        .expandable-component {
            .expandable-content {
                overflow: visible;

                .loading-wrapper {
                    position: inherit;
                }

                .kpi-list {
                    width: calc(100% - 24px);
                    margin-left: 24px;
                }
            }

            .header {
                @include shadow-inset-alto;
                padding: 8px;
                min-height: 40px;

                .open-close-container {
                    margin-left: 0px;
                    margin-right: 10px;
                }

                .title-content {
                    .asset-list-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .asset-list-item-name {
                            overflow-wrap: break-word;
                            width: calc(100% - 16px);
                            padding-right: 9px;
                            padding-left: 9px;
                            line-height: 16px;
                            text-align: left;
                            word-break: break-all;
                        }
                    }
                }
            }
        }
    }
}