@use '~theme/typography'as *;
@use '~theme/_colors'as *;

.notification-groups-container {
    .notification-groups-header {
        display: flex;
        height: 40px;
        align-items: center;
        border-bottom: 1px solid black;

        .header-item {
            display: flex;
            color: $dove-gray;
            @include font-medium-text-md;

            &.notification-type {
                margin-left: 15px;
                min-width: 200px;
                width: 30%;
            }

            &.channels {
                width: 70%;
            }
        }
    }

    .notification-groups-list {
        display: grid;

        .expandable-component {
            .header {
                height: 40px;
                background-color: $gallery;
                border-bottom: 1px solid $silver;
                @include font-medium-text-md;
                font-size: 16px;
                width: 100%;
                text-align: left;

                .open-close-container {
                    margin: 0px 15px;
                }

                .title-content {
                    button {
                        line-height: 30px;
                        border: none;
                        background-color: transparent;
                    }
                }
            }
        }

        .notification-type-row {
            display: flex;
            min-height: 40px;
            align-items: center;
            border-bottom: 1px solid $gallery;

            .notification-type-channels-group {
                display: flex;
                width: 70%;
            }

            .notification-type-col {
                display: flex;
                width: 100%;
                align-items: center;
                @include font-regular-text-md;
                padding: 5px 0px;

                &.type-name {
                    width: 30%;
                    min-width: 200px;
                    padding-left: 15px;
                    margin-right: 10px;
                }

                &.channel {
                    padding-right: 10px;
                }

                .switch-label {
                    padding-left: 10px;
                }
            }
        }
    }
}