@use "~theme/colors" as *;
@use "~theme/mixins" as *;

#overview-condition-container {
    display: flex;
    column-gap: 24px;
    row-gap: 24px;
    margin: 24px 10px 0 3px;

    #overview-condition-powertrains, #overview-condition-assets {
        @include create-border;
        width: 50%;
        height: 50vh;
        min-width: 0;
        min-height: 325px;
    }

}