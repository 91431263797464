@use '~theme/colors' as *;
@use "~theme/typography"as *;
@use "~theme/breakpoints" as *;

#overview-condition-assets {

    .asset-condition-title {
        padding: 19px 0px 0px 32px;
        @include font-medium-text-lg;
        margin-bottom: 24px;
    }

    .dashboard-style {
        height: max(calc(100% - 100px), 36vmin);
        @include customScreenMinWidth(1250) {
            width: max(600px, 44vmin);
        }
    }

    .asset-type-filter {
        padding-left: 32px;
        width: 200px;
        .general-select__placeholder {
            color: $mine-shaft;
        }
        .general-select__menu .general-select__menu-list .general-select__option {
            display: flex;
            align-items: center;
            column-gap: 10px;
        }
    }

    .no-assets-data {
        height: 100%;
    }

}