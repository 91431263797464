@use "~theme/colors"as *;
@use "~theme/typography"as *;

.user-limits-dialog {
    min-width: 960px;
    position: relative;
    @include font-medium-text-md;

    .modal-content {
        min-height: 620px;

        .modal-body {
            margin-top: 20px;

            .user-limits-content {
                .default-user-limits {
                    .section-heading {
                        @include font-medium-text-lg;
                        line-height: 24px;
                        margin-left: 8px;
                    }

                    .default-user-limits-table {
                        padding-top: 17px;

                        .default-user-limits-headers {
                            position: relative;
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            border-bottom: 1px solid $black;

                            .default-user-limits-header {
                                @include font-medium-text-sm;
                                margin-bottom: 8px;
                                margin-left: 16px;
                                float: left;
                                line-height: 14px;
                                text-transform: uppercase;
                                color: $dove-gray;
                            }

                            #default-kpi-name-header {
                                width: 250px;
                            }

                            #default-user-limit-header {
                                width: 500px;
                            }

                            #default-enable-email-alert-header {
                                width: 170px;
                            }
                        }

                        .default-user-limits-content {
                            .default-user-limits-row {
                                position: relative;
                                display: flex;
                                flex-direction: row;
                                width: 100%;

                                border-bottom: 1px solid $silver;
                                padding: 8px;
                                min-height: 40px;
                                overflow-wrap: break-word;
                                .default-user-limits-item {
                                    @include font-regular-text-md;
                                    color: $mine-shaft;
                                    margin-left: 16px;
                                    display: flex;
                                    align-items: center;
                                }

                                .default-limit-kpi-name {
                                    width: 250px;
                                }

                                .default-limit-value {
                                    width: 500px;
                                }

                                .default-limit-enable-alert {
                                    width: 170px;
                                    line-height: 20px;
                                    margin-top: 8px;
                                }
                            }
                        }
                    }

                    .no-default-user-limits {
                        text-align: center;
                    }
                }

                .custom-user-limits {
                    margin-top: 32px;

                    .section-heading {
                        @include font-medium-text-lg;
                        line-height: 24px;
                        margin-left: 8px;
                    }

                    .custom-user-limits-table {
                        padding-top: 17px;

                        .custom-user-limits-headers {
                            position: relative;
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            border-bottom: 1px solid $black;

                            .custom-user-limits-header {
                                @include font-medium-text-sm;
                                margin-bottom: 8px;
                                margin-left: 16px;
                                float: left;
                                line-height: 14px;
                                text-transform: uppercase;
                                color: $dove-gray;
                                width: 185px;
                            }
                        }

                        .template-user-limit {
                            position: relative;
                            display: flex;
                            flex-direction: row;
                            height: 72px;
                            border-bottom: 1px solid $silver;

                            .template-user-limit-item {
                                margin-right: 10px;

                                .pth-dropdown,
                                input {
                                    width: 100%;
                                    border-color: $silver;
                                    border-style: solid;
                                    border-width: 1px;
                                }

                                .user-limit-input-name {
                                    height: 32px;
                                }

                                .user-limit-input-value {
                                    height: 32px;
                                    width: 75%;
                                }

                                .user-limit-input-value::-webkit-outer-spin-button,
                                .user-limit-input-value::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    -moz-appearance: textfield;
                                    margin: 0;
                                }

                                .user-limit-unit {
                                    height: 32px;
                                    width: 25%;
                                }

                                .custom-checkbox {
                                    margin-top: 7px;
                                }

                                #template_kpi_dropdown {
                                    margin-top: 16px;
                                    margin-left: 10px;
                                    width: 172px;

                                    .general-select__placeholder {
                                        @include font-regular-text-md;
                                        color: $silver-chalice;
                                    }

                                    .general-select__option--is-disabled {
                                        border-top: solid 1px $silver;
                                        margin-top: 10px;
                                        margin-left: 12px;
                                        width: 85%;
                                        cursor: default;
                                    }
                                }
                                #template_input_user_limit_name {
                                    margin-top: 8px;
                                
                                    &::placeholder {
                                        color: $silver-chalice;
                                    }
                                }

                                #template_comparison_dropdown {
                                    margin-top: 16px;
                                    width: 172px;

                                    .general-select__placeholder {
                                        @include font-regular-text-md;
                                        color: $silver-chalice;
                                    }

                                    input {
                                        padding-left: 8px;

                                        &::placeholder {
                                            color: $silver-chalice;
                                        }
                                    }
                                }

                                #template_input_user_limit_value {
                                    margin-top: 8px;
                                    width: 124px;

                                    input {
                                        padding-left: 8px;
                                        padding-right: 8px;

                                        &::placeholder {
                                            color: $silver-chalice;
                                        }
                                    }
                                }

                                #template_input_user_limit_unit {
                                    margin-top: 8px;
                                    width: 48px;

                                    .input {
                                        .input-container {
                                            input {
                                                border-left: none;
                                                padding: 5px;
                                            }
                                        }
                                    }
                                }

                                .template-alert-checkbox {
                                    margin-top: 20px;
                                }

                                #add_button {
                                    @include font-regular-text-md;
                                    line-height: 16px;
                                    margin-top: 16px;
                                    height: 32px;
                                    width: max-content;
                                }
                            }

                            .template-user-limit-item:nth-of-type(4) {
                                display: flex;
                            }
                        }

                        .custom-user-limits-content {
                            max-height: 200px;
                            overflow: auto;
                            overflow-x: hidden;
                            overflow-y: overlay;

                            .custom-user-limits-row {
                                position: relative;
                                display: flex;
                                flex-direction: row;
                                height: 40px;
                                width: 100%;
                                border-bottom: 1px solid $silver;

                                .custom-user-limits-item {
                                    @include font-regular-text-md;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    color: $mine-shaft;
                                    line-height: 40px;
                                    margin-left: 16px;

                                    .delete-custom-user-limit {
                                        margin-left: 55px;
                                        cursor: pointer;
                                    }
                                }

                                .custom-limit-kpi-name {
                                    width: 169px;
                                }

                                .custom-limit-name,
                                .custom-limit-compariston-type,
                                .custom-limit-value {
                                    width: 169px;
                                }

                                .custom-limit-enable-alert {
                                    line-height: 20px;
                                    margin-top: 8px;
                                    margin-left: 0px;
                                }
                            }
                        }
                    }
                }

                .buttons-row {
                    width: 100%;
                    margin-top: 200px;
                    float: left;

                    button {
                        float: right;
                        margin-left: 10px;
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}

.general-dialog-container {
    .unsaved-changes-warning-popup,
    .delete-user-limit-popup {
        @include font-regular-text-md;

        .dialog-accept-button {
            background-color: $dodger-blue;
        }
    }
}
