@use '~theme/colors'as *;
@use '~theme/mixins'as *;
@use '~theme/typography'as *;


.powertrain-assets-overview-container {
    flex-direction: column;
    height: 100%;
    padding: 0px 16px 26px 0;

    .overview-title {
        @include font-bold-subtitle;
		line-height: 24px;
		font-weight: bold;
        margin: 24px 0px;
    }

    .toggle-and-search-row {
        display: flex;

        .toggle-button-container .toggle-button {
            width: 110px;
            font-size: 14px;
            line-height: 16px;
            float: left;
        }

        .right-section {
            display: flex;
            float: right;
            margin-left: auto;

            .nr-of-assets {
                margin-right: 12px;
                margin-top: 6px;
                color: $dove-gray;
                @include font-regular-text-md;
            }

            .filter-icon {
                border: none;
                background-color: transparent;
                margin: 0px 12px;
                cursor: pointer;
            }

            .search-button-container {
                svg {
                    margin-top: -5px;
                }
            }
        }
    }
}
