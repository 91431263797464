@use '~theme/colors'as *;
@use '~theme/mixins'as *;
@use '~theme/typography'as *;


.overview-scrollable-list {
    height: -moz-calc(100% - 155px);
    height: -webkit-calc(100% - 155px);
    height: calc(100% - 155px);

    &.with-filter {
        height: -moz-calc(100% - 230px);
        height: -webkit-calc(100% - 230px);
        height: calc(100% - 230px);
    }

    .asset-list-header {
        height: 40px;
        display: flex;
        padding: 12px 14px;

        .header-item {
            width: 100%;
            display: flex;
            color: $dove-gray;
            text-transform: uppercase;
            font-weight: bold;
            font-family: $font-family-regular;
            font-size: 12px;

            .info-icon {
                margin-left: 5px;
                margin-top: -1px;
                cursor: pointer;
            }

            .column-icon {
                margin-right: 5px;
                margin-top: -2px;
                margin-left: -5px;
            }

            .info-button-with-online-help {
                text-transform: none;
                font-weight: 100;
            }
        }
    }

    .item {
        border-bottom: 1px solid $alto;
    }

    .item, .title-content {
        @include font-regular-text-md;
        line-height: 27px;
        display: flex;
        padding: 6px;
        width: 100%;

        .item-name {
            width: 100%;
            padding-right: 4px;
            display: flex;
            max-width: 33.33%;
            word-break: break-word;

            .item-icon {
                display: flex;
                align-items: center;
                margin-right: 10px;
            }

            .name {
                min-height: 27px;
                text-align: left;
            };
        }

        .condition-index {
            width: 100%;
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
            }

            .kpis {
                .nr-of-kpis {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            .kpi-list {
                margin: 3px;

                .kpi-list-item {
                    min-height: 22px;
                    display: flex;

                    svg {
                        margin-top: 2px;
                    }

                    p {
                        margin: 0px;
                    }
                }
            }
        }

        .nr-by-condition-index {
            display: inline-flex;
            gap: 5px;

            div {
                height: 15px;
                align-content: center;
            }

            div:not(:last-child) {
                padding-right: 5px;
                border-right: 1px solid $alto;
            }       
        }
    }

    .title-content {
        .item-name {
            width: -moz-calc(100% - 50px);
            width: -webkit-calc(100% - 50px);
            width: calc(100% - 50px);
            }
    }

    .expandable-component {
        .header {
            border-bottom: 1px solid $alto;
            background-color: white;

            .title-content {
                line-height: 27px;
                @include font-regular-text-md;
            }
        }

        .expandable-content {
            .asset-list-of-asset-group {
                .item {
                    background-color: $wild-sand;

                    .item-name {
                        margin-left: 40px;
                        width: calc(100% - 120px);
                    }
                }
            }

            .no-available-assets {
                text-align: center;
                @include font-regular-text-md;
                line-height: 27px;
                padding: 6px;
                background-color: $wild-sand;
                color: $dove-gray;
            }
        }
    }
}