@use "~theme/typography" as *;

#asset-details-container {
	.conditions-panel-container {
		width: 70%;
		margin-top: 5px;
		float: left;
		.row {
			> .condition-col {
				@include font-regular-text-md;
				display: flex;
				align-items: center;
				margin-top: 15px;
				line-height: 16px;

				&.overall-condition {
					@include font-medium-text-md;
					line-height: 16px;
				}
			}
		}
	}

	.overall-condition {
		@include font-medium-text-md;
		line-height: 16px;
	}
}
