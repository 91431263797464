@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use "~theme/breakpoints" as *;

.dashboard-style {
    height: 100%;
    width: 100%;
    .chart-style {
        height: 100%;
        width: 100%;
    }
    .pie-chart-middle-title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        width: 100px;
        .has-text {
            @include font-medium-title-xl;
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 1.1;
            @include xl-max-w {
                font-size: 40px;
            }
                > span {
                    @include font-regular-subtitle;
                    color: $dove-gray;
                    text-align: center;
                    word-break: break-all;

                    @include xl-max-w {
                        white-space: normal;
                    }

                    @include customScreenMinWidthAndMaxHeight(1200, 850)  {
                        white-space: normal;
                    }
                }
        }
    }
    .overview-chart {
        .highcharts-legend-item {
            rect {
                visibility: hidden;
            }
        }
    }

    .highcharts-legend-item {
        .condition-container-row {
            @include font-regular-text-md;
            font-weight: normal;
            color: $mine-shaft;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 8px;
        }
        .legend-value {
            @include font-medium-text-md;
            margin-right: 5px;
        }
        .condition-icon {
            margin-right: 8px;
        }
    }
}
