@use "~theme/colors" as *;
@use '~theme/mixins'as *;
@use '~theme/typography'as *;
@use "~theme/breakpoints" as *;

.event-detailsCMD-content {
	margin-top: 25px;

	.event-details-header {
		@include font-regular-text-md;
		display: flex;
		align-items: center;
		background-color: $wild-sand;
		margin: 0;
        line-height: 16px;
		padding: 15px;
		padding-bottom: 0;

		.event-type-icon{
            display:flex;
            align-items: start;
            padding-bottom: 14px;
        }
		.event-type-group {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 100%;

			@include xl-min-w {
				flex-direction: row;
			}

			.event-name {
				min-width: 200px;
			}

			.event-logged-on {
				min-width: 220px;
			}
			& > * {
				padding: 0 9px 14px 9px;
				min-height: 1rem;
				word-break: break-word;
			}
		}

		.back-to-event-list {
			@include font-medium-text-md;
			min-width: 200px;
			flex: auto;

			.switch-component {
				float: right;
				.event-list-lbl {
					text-decoration: underline;
					color: $dodger-blue;
					cursor: pointer;
				}
			}
		}

	}

	.event-details-tab-selector {
		margin-top: 20px;
	}

	#event-details-container {
		margin-top: 10px;
	}
}
