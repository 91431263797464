@use '~theme/colors' as *;
@use "~theme/typography"as *;
@use "~theme/breakpoints" as *;

#overview-condition-powertrains {

    .powertrain-condition-title {
        padding: 19px 0px 0px 32px;
        @include font-medium-text-lg;
        margin-bottom: 56px;
    }

    .dashboard-style {
        height: max(calc(100% - 100px), 36vmin);
        @include customScreenMinWidth(1250) {
            width: max(600px, 44vmin);
        }
    }

    .no-powertrains-data {
        height: 100%;
    }

}