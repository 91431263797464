@use "~theme/colors" as *;
@use "~theme/typography" as *;

.email-alert-modal.modal {
	.general-dialog {
		width: 800px;

		.modal-header {
			padding: 10px 25px;
		}

		.modal-content {
			height: 550px;
		}
	}

	.settings-container {
		margin-top: 20px;
		@include font-regular-text-md;
		line-height: 16px;
		padding-left: 0;
		padding-right: 0;

		> .row {
			padding: 10px 0px;
		}

		.row {
			>* {
				width: auto; 
			}
			
		}

		.maxEmailAlertsCheckbox {
			top: -3px;
			margin-right: 10px;
			line-height: 18px;
		}

		.max-number-input {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 90px;
			height: 32px;
			margin: -8px 5px 0px 5px;

		}

		input[type="checkbox"] {
			width: 16px;
			height: 16px;
			margin-right: 5px;
		}

		.select-asset-container {
			display: inline;
			width: 100%;
		}

		.select-asset-label {
			font-size: 14px;
			padding: 5px 0px;
			color: $dove-gray;
		}

		.asset-selector-row {
			width: 280px;
		}

		#email-alert-selected-assets {
			div {
				border-radius: 0px;
			}
		}

		.dropdown-explanation {
			font-size: 14px;
		}

		.options-container {
			.row {
				padding: 5px 0px;

				.custom-checkbox {
					padding-right: 3px;
				}

				span {
					margin-top: 3px;
				}
			}
		}
	}
}
